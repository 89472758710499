<template>
  <div class="sketchy-breadcrumb-bar">
    <div class="bar">
      <div
        class="fill"
        :style="{width: stepPercentage * step + '%'}"
      ></div>

      <div
        class="marker"
        :class="{invisible: step === i}"
        v-for="i in steps"
        :key="i"
        :style="{left: stepPercentage * i + '%'}"
      ></div>

      <div
        class="dot"
        :style="{left: 'calc(' +stepPercentage * step + '% - 7px)'}"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BarreProgression',

  computed: {
    stepPercentage() {
      return 100.0 / (this.steps+1);
    }
  },

  props: ['step', 'steps'],
}
</script>

<style lang="scss" scoped>
$bar-height: 6px;
$bar-color: #62aafc;
$transition-delay: 350ms;

.sketchy-breadcrumb-bar {
  padding: 0;
  margin: 0;

  width: 100%;
  height: $bar-height;

  display: flex;
  flex-direction: row;

  .bar {
    position: relative;

    width: 100%;
    height: 100%;

    background-color: #c5ced8;
    border-radius: $bar-height;

    .fill {
      position: absolute;

      width: 0;
      height: 100%;

      top: 0;
      left: 0;

      background-color: $bar-color;
      border-radius: $bar-height;

      transition: width $transition-delay;
    }

    .marker {
      position: absolute;

      $overflow: 100%;

      width: 2px;
      height: 100% + $overflow;

      top: -$overflow/2;
      left: 0;

      background-color: #004375;
      transition: left $transition-delay;

      opacity: 1;
      &.invisible {
        opacity: 0;
        transition: left $transition-delay, opacity $transition-delay;
      }
    }

    .dot {
      position: absolute;

      $size: 15px;
      width: $size;
      height: $size;
      border-radius: $size;

      top: -5px;

      margin: 0;
      padding: 0;
      border: 0;

      background-color: $bar-color;
      transition: left $transition-delay;

      &:after {
        display: block;
        position: absolute;
        content: '';

        $size: 11px;
        width: $size;
        height: $size;

        top: 2px;
        left: 2px;

        margin: 0;
        padding: 0;
        border: 0;

        border-radius: 50%;

        background-color: white;
      }
    }
  }
}
</style>
